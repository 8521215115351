.button {
	@include flex($ai: center, $jc: center);
	background-color: $O-white;
	color: $O-black;
	border: 2px solid $O-black;
	padding: var(--size-md) var(--size-xl);
	gap: var(--size-sm);
	font: $b-32;
	font-weight: 700;
	transition: all 0.15s ease-in-out;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	cursor: pointer;
	i[class^='icon-'] {
		font-size: $size-xl;
	}

	&:focus-visible {
		outline: none;
	}

	&[disabled] {
		border-color: $O-neutral-03;
		color: $O-neutral-03;
		pointer-events: none;
	}

	&--sm {
		min-width: rem(208);
		padding: var(--size-sm);
		gap: var(--size-2xs);
		font: $b-18;
		font-weight: 400;
		i[class^='icon-'] {
			font-size: $size-md;
		}
	}

	&--xs {
		padding: var(--size-2xs);
		gap: var(--size-2xs);
		font: $b-18;
		font-weight: 400;
		border-color: $O-neutral-03;
	}

	//FILLED
	&--filled {
		background-color: $O-black;
		color: $O-white;
		border: 2px solid $O-black;
		&.button--sm {
			min-width: inherit;
			font-weight: 700;
		}
		&[disabled] {
			background-color: $O-neutral-03;
			border-color: $O-neutral-03;
			color: $O-neutral-01;
		}
	}

	//IMPORTANT
	&--important {
		background-color: $O-orange;
		color: $O-black;
		border: 2px solid $O-orange;
		&.button--sm {
			min-width: inherit;
			font-weight: 700;
		}
		&[disabled] {
			background-color: $O-neutral-03;
			border-color: $O-neutral-03;
			color: $O-neutral-01;
		}
	}

	//NEGATIVE
	&--negative {
		background-color: $O-functional-red;
		color: $O-white;
		border: 2px solid $O-functional-red;
		&[disabled] {
			background-color: $O-neutral-03;
			border-color: $O-neutral-03;
			color: $O-neutral-01;
		}
	}

	//GHOST
	&--ghost {
		background-color: $O-black;
		color: $O-white;
		border: 1px solid $O-white;
		&[disabled] {
			border-color: $O-neutral-04;
			color: $O-neutral-04;
		}
	}

	//LOADER
	&--loader {
		position: relative;
		color: $O-orange;
		//SPINNER INSIDE BUTTON
		&:before {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: rem(32);
			height: rem(32);
			background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path fill="%23000" d="M43.935,25.145c0-10.318-8.364-18.688-18.681-18.688c-10.317,0-18.681,8.37-18.681,18.688h4.068c0-8.072,6.542-14.62,14.613-14.62c8.07,0,14.613,6.548,14.613,14.62H43.935z" transform="rotate(292.281 25 25)"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="1s" repeatCount="indefinite"/></path></svg>');
			background-size: contain;
			background-repeat: no-repeat;
		}
	}

	&-group {
		@include flex($ai: center);
		gap: var(--size-md);
	}
}
