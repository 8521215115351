.keyboard {
	border-top: 1px solid $O-neutral-02;
	background: $O-white;
	box-shadow: 8px -8px 40px 0px rgba(0, 0, 0, 0.05);
	padding: var(--size-xl);
	.hg-theme-default {
		background: $O-white;
		border-radius: 0;
		font: $b-32;
		font-weight: 700;
		color: $O-neutral-05;
		padding: 0;
		.hg-row {
			&:not(:last-child) {
				margin: 0;
			}
			&:last-child {
				padding-top: var(--size-2xs);
				.hg-button {
					@include flex-child($fs: 0, $fg: inherit);
					&.hg-button-alt {
						width: fit-content;
					}
					&.hg-button-space {
						@include flex-child($fg: 2);
					}
				}
			}
		}
		.hg-button {
			box-shadow: none;
			padding: var(--size-md);
			border-radius: 0;
			border-bottom: none;
			width: rem(100);
			height: rem(100);
			border-radius: $br-md;
			&.hg-activeButton:not(.hg-button-space) {
				background-color: $O-neutral-02;
			}
			&.hg-button-bksp {
				&:before {
					content: '\e91a';
					font-family: 'kiosko' !important;
					font-size: $size-xl;
				}
				span {
					display: none;
				}
			}
			&.hg-button-enter {
				&:before {
					content: '\e90d';
					font-family: 'kiosko' !important;
					font-size: $size-xl;
					transform: scaleY(-1);
					filter: FlipV;
				}
				span {
					display: none;
				}
			}
			&.hg-button-space {
				span {
					background-color: $O-neutral-02;
					border-radius: $br-md;
					height: rem(80);
					width: 100%;
				}
				&.hg-activeButton {
					background-color: transparent;
					span {
						background-color: $O-neutral-03;
					}
				}
			}
			&.hg-button-shiftactivated {
				background-color: $O-neutral-02;
			}
		}
		.hg-row .hg-button-container,
		.hg-row .hg-button:not(:last-child) {
			margin-right: 0;
		}

		//NUMERIC
		&.hg-layout-numeric {
			max-width: rem(360);
			margin: 0 auto;
			.hg-rows,
			.hg-row {
				gap: var(--size-md);
				justify-content: center;
			}
			.hg-button {
				border-radius: $br-lg;
				border: 1px solid $O-neutral-03;
				width: rem(88);
				height: rem(88);
				flex-grow: 0;
			}
			.hg-row {
				margin-top: var(--size-md);
				&:last-child {
					padding-top: 0;
					.hg-button {
						flex-grow: 0;
						&:first-child {
							opacity: 0;
							pointer-events: none;
						}
					}
				}
			}
		}
	}
	&.hidden {
		display: none;
	}
}
