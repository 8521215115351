.info-group{
    @include flex($ai:flex-start);
    gap: var(--size-sm);
    &__text{
        @include flex($fd:column);
        font: $b-18;
        label{
            font-weight: 700;
        }
        span{
            color: $O-neutral-04;
        }
    }
}