.tile {
	@include flex($fd:column);
	border: 1px solid $O-neutral-03;
	gap: var(--size-md);
	padding: var(--size-md);
	background-color: $O-white;
	&--banner{
		padding: var(--size-md) var(--size-lg);
		background-color: $O-feedback-blue;
		.tile{
			@include flex($fd:column);
			gap: var(--size-md);
			&__header{
				@include flex($fd:column);
				gap: var(--size-2xs);
				i[class^='icon-']{
					font-size: $size-xl;
				}
				span{
					font: $b-18;
				}
			}
			&__actions{
				@include flex($ai:center);
				gap: var(--size-md);
				&__summary{
					@include flex();
					gap: var(--size-sm);
					background-color: $O-white;
					padding: var(--size-sm);
					border: 1px solid $O-neutral-04;
					font: $b-18;
					color: $O-neutral-05;
				}
				.button{
					min-width: inherit;
				}
			}
		}
	}
}