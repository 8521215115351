.form-group{
    @include flex($fd:column);
    width: 100%;
    gap: var(--size-sm);
    color: $O-black;
    &__text{
        @include flex($fd:column);
        gap: var(--size-4xs);
        label{
            @include flex($ai:center);
            gap: var(--size-2xs);
            font: $b-24;
            font-weight: 700;
            color: $O-black;
        }
    }

    &__input{
        @include position ($p: relative);
        > .button-icon{
            @include position ($p: absolute, $r:3px, $t:50%);
            transform: translateY(-50%);
            border: none;
        }
    }

    &__error-message{
        @include flex($ai:center);
        gap: var(--size-2xs);
        font: $b-18;
        text-align: left;
        i[class^='icon-']{
            color: $O-functional-red;
        }
    }
}