.footer{
    @include flex($jc:space-between);
    background-color: $O-white;
    border-top: 1px solid $O-neutral-03;
    padding: var(--size-xl) var(--size-3xl);
    gap: var(--size-md);
    .button{
        &--sm{
            min-width: inherit;
        }
    }
}