.progress{
    &__bar{
        @include position($p: relative);
        height: $size-sm;
        background-color: $O-neutral-02;
        width: 100%;
        &__color{
            @include position($p: absolute, $t:0, $b:0);
            background-color: $O-orange;
            transition: all 1s ease-in-out;
        }
    }

    //COLORS
    &--sucess{
        .progress{
            &__bar{
                &__color{
                    background-color: $O-functional-green;
                }
            }
        }
    }
    &--error{
        .progress{
            &__bar{
                &__color{
                    background-color: $O-functional-red;
                }
            }
        }
    }

    //SIZEs
    &--sm{
        .progress{
            &__bar{
                height: $size-3xs;
            }
        }
    }
    &--lg{
        .progress{
            &__bar{
                height: $size-sm;
            }
        }
    }
}