.item {
	border: 1px solid $O-neutral-03;
	width: 100%;
	&__tarifa {
		padding: var(--size-2xs);
		background-color: $O-supporting-blue;
		min-width: rem(284);
		width: fit-content;
		&--green {
			background-color: $O-supporting-green;
		}
		&--purple {
			background-color: $O-supporting-purple;
		}
	}
	&__body {
		@include flex();
		gap: var(--size-md);
		padding: var(--size-sm) var(--size-md);
		&__title {
			@include flex($fd: column);
			gap: var(--size-md);
			@include flex-child($fg: 2);
			.title {
				&__content {
					@include flex($jc: space-between, $ai: center);
					gap: var(--size-xl);
					&__info {
						@include flex($fd: column);
						//gap: var(--size-md);
						h3 {
							color: $O-orange;
							margin-bottom: var(--size-md);
						}
						.alert {
							width: 100%;
							font: $b-14;
							line-height: rem(18);
							color: $O-black;
							gap: var(--size-2xs);
							padding: var(--size-3xs) var(--size-2xs);
							margin-top: var(--size-3xs);
							i {
								color: $O-black;
							}
						}
					}
					&__quantity {
						@include flex($fd: column);
						gap: var(--size-2xs);
						h4 {
							padding: $size-xl;
						}
					}
				}
			}
		}
		&__details {
			@include flex($ai: center, $jc: space-between);
			@include flex-child($fs: 0);
			gap: var(--size-xl);
			max-width: 60%;
			width: 100%;
			.details {
				&__info {
					@include flex($fd: column);
					gap: var(--size-md);
				}
				&__buttons {
					@include flex($fd: column, $ai: center);
					@include flex-child($fs: 0);
					gap: var(--size-md);
				}
			}
		}
		.alert {
			width: 100%;
		}
	}

	&-group {
		@include flex($fd: column);
		gap: var(--size-3xl);
		&--sm {
			gap: var(--size-sm);
		}
	}

	&--sm {
		border: 1px solid $O-black;
		.item {
			&__body {
				@include flex($fd: column);
				gap: var(--size-2xs);
				&__title {
					color: $O-neutral-05;
				}
				&__text {
					font: $b-18;
					color: $O-black;
				}
			}
		}
	}
}
