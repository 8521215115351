.loader {
	@include flex($fd: column, $ai: center, $jc: center);
	@include flex-child($fg: 2);
	gap: var(--size-4xl);
	font: $b-32;
	text-align: center;
	max-width: rem(768);
	margin: auto;
	ng-lottie {
		min-height: rem(520);
	}
	.spinner {
		animation: rotateSpinner 1.5s linear infinite;
		@keyframes rotateSpinner {
			to {
				transform: rotate(360deg);
			}
		}
	}
	&__text {
		@include flex($fd: column, $ai: center);
		gap: var(--size-md);
		&__description {
			color: $O-neutral-04;
			font: $b-24;
		}
	}
}
