.nav {
	@include flex-child($fs: 0);
	@include flex($fd: column, $jc: center);
	background-color: $O-white;
	padding: var(--size-xl) var(--size-3xl);
	gap: var(--size-md);
	border-top: 1px solid $O-neutral-03;
	> span {
		font: $b-24;
		color: $O-neutral-05;
		a {
			color: $O-neutral-05;
		}
	}
	.button-group {
		@include flex($ai: center);
		gap: var(--size-md);
		button {
			flex: 1;
		}
	}
	.spinner {
		max-width: rem(144);
	}
}
