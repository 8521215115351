@font-face {
	font-family: 'inter';
	src: url('../fonts/Inter-Bold.woff') format('woff');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'inter';
	src: url('../fonts/Inter-Regular.woff') format('woff');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'kiosko';
	src: url('../fonts/kiosko.woff?in57d3') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'kiosko' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

i[class^='icon-'] {
	&.icon {
		&--xs {
			font-size: $size-md;
		}
		&--sm {
			font-size: $size-xl;
		}
		&--md {
			font-size: $size-3xl;
		}
		&--lg {
			font-size: 8rem;
		}
	}
}
.icon-passport:before {
	content: '\e920';
}
.icon-dni:before {
	content: '\e921';
}
.icon-gb:before {
	content: '\e91f';
}
.icon-file:before {
	content: '\e91e';
}
.icon-mobile:before {
	content: '\e922';
}
.icon-present:before {
	content: '\e923';
}
.icon-triangle-down:before {
	content: '\e924';
}
.icon-triangle-up:before {
	content: '\e925';
}
.icon-card:before {
	content: '\e900';
}
.icon-roaming:before {
	content: '\e901';
}
.icon-e-sim:before {
	content: '\e902';
}
.icon-esim-1:before {
	content: '\e903';
}
.icon-sim-2:before {
	content: '\e904';
}
.icon-euro:before {
	content: '\e905';
}
.icon-printer:before {
	content: '\e906';
}
.icon-top-up-euro:before {
	content: '\e907';
}
.icon-esim:before {
	content: '\e908';
}
.icon-sim:before {
	content: '\e909';
}
.icon-chevron-left:before {
	content: '\e90a';
}
.icon-check:before {
	content: '\e90b';
}
.icon-mail:before {
	content: '\e90c';
}
.icon-enter:before {
	content: '\e90d';
}
.icon-chevron-down:before {
	content: '\e90e';
}
.icon-chevron-up:before {
	content: '\e90f';
}
.icon-less:before {
	content: '\e910';
}
.icon-add:before {
	content: '\e911';
}
.icon-log-out:before {
	content: '\e912';
}
.icon-internet:before {
	content: '\e913';
}
.icon-call:before {
	content: '\e914';
}
.icon-close:before {
	content: '\e915';
}
.icon-language-2:before {
	content: '\e916';
}
.icon-help:before {
	content: '\e917';
}
.icon-info:before {
	content: '\e918';
}
.icon-check-rounded:before {
	content: '\e919';
}
.icon-erase:before {
	content: '\e91a';
}
.icon-arrow-right:before {
	content: '\e91b';
}
.icon-arrow-left:before {
	content: '\e91c';
}
.icon-accessibility:before {
	content: '\e91d';
}
