.carousel {
    @include position ($p: relative);
    height: 100%;
    &__item{
        @include position ($p: absolute, $t:0, $b:0, $r:0, $l:0);
        opacity: 0;
        transition: all .30s ease-in-out;
        &.show{
            opacity: 1;
            transition: all .30s ease-in-out;
        }
    }
}
