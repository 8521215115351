.accesibility-mode {
	--size-md: 1rem; //16
	--size-xl: 1.5rem; //24
	--size-5xl: 10.5rem; //168

	.accessibility-block {
		height: rem(696);
		transition: all 0.5s ease-in-out;
		img {
			opacity: 1;
			transition: all 0.3s ease-in-out 0.5s;
		}
	}

	.header {
		&__title {
			@include flex($jc: center);
			&__logo {
				display: none;
			}
		}
	}

	.main {
		padding: var(--size-5xl) var(--size-3xl) var(--size-3xl) var(--size-3xl);
		+ app-footer {
			box-shadow: 0px -43px 56px rgba(255, 255, 255, 0.8);
		}
		&.form-page {
			padding: var(--size-3xl) var(--size-3xl);
			gap: var(--size-md);
			@include flex($jc: flex-end);
			.main {
				&__content {
					@include flex-child($fg: 0);
				}
			}
			&--numbers {
				padding: var(--size-3xl);
				@include flex($jc: flex-end);
				//TEMPORAL
				gap: rem(40);
				.main {
					&__content {
						@include flex-child($fg: 0);
					}
				}
			}
		}
	}

	.card {
		padding: var(--size-4xl) var(--size-xl);
		&--sm {
			padding: var(--size-xl) var(--size-md);
		}
	}

	.nav {
		padding: var(--size-xl) var(--size-3xl);
		box-shadow: 0px -43px 56px rgba(255, 255, 255, 0.8);
		.button {
			font: $b-24;
			font-weight: 700;
		}
		> span {
			font: $b-18;
		}
	}

	.footer {
		padding: var(--size-xl) var(--size-3xl);
		.button-language {
			.button {
				gap: 0;
			}
			*:not(.icon-language-2) {
				font-size: 0;
			}
		}
	}

	.keyboard {
		padding: var(--size-md) var(--size-3xl);
		.hg-theme-default {
			.hg-button {
				width: var(--size-4xl);
				height: rem(40);
				padding: var(--size-2xs) var(--size-sm);
				font: $b-24;
				font-weight: 700;
				&.hg-button-space {
					span {
						height: var(--size-2xl);
					}
				}
			}
			.hg-row {
				+ .hg-row {
					padding-top: var(--size-2xs);
				}
			}
		}
		+ .nav {
			box-shadow: none;
		}
	}

	.modal {
		@include flex($ai: flex-end);
	}

	.loader {
		font: $b-24;
		gap: $size-3xl;
		&__text {
			&__description {
				font: $b-18;
			}
		}
		ng-lottie {
			min-height: rem(472);
			max-height: rem(472);
		}
	}
}
