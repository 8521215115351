.modal {
	background-color: rgba($O-black, $alpha: 0.6);
	@include position($p: fixed, $t: 0, $r: 0, $l: 0, $b: 0);
	@include flex($jc: center, $ai: center);
	padding: var(--size-lg);
	opacity: 0;
	visibility: hidden;

	&__content {
		@include flex($fd: column);
		gap: var(--size-4xl);
		@include flex-child($fg: 2, $fs: 0);
		background-color: $O-white;
		max-width: rem(880);
		padding: var(--size-4xl);
		width: 100%;

		&__body {
			@include flex($fd: column);
			gap: var(--size-xl);
			.button-toggle-group {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
			}
			.body {
				&__header {
					@include flex($fd: column);
					gap: var(--size-2xs);
					span {
						font: $b-24;
						color: $O-neutral-04;
					}
				}
			}
			> span {
				color: $O-neutral-04;
			}
		}
		&__buttons {
			@include flex($fd: column);
			gap: var(--size-md);
		}
	}

	&.show {
		opacity: 1;
		visibility: visible;
		z-index: 10;
	}
}

.mat-mdc-dialog-container .mdc-dialog__container {
	transition: none !important;
}

.modal-accessibility {
	.modal {
		@include flex($ai: flex-end);
	}
}
