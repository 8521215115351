.input-quantity {
	@include flex($ai: center);
	border: 2px solid $O-neutral-03;
	background-color: $O-white;
	width: fit-content;
	.input {
		border: none;
		min-width: $size-lg;
		max-width: $size-xl;
		padding: var(--size-2xs);
		text-align: center;
		transition: none;
		font: $b-18;
		font-weight: 700;
		color: $O-black;
		&[type='number'] {
			-moz-appearance: textfield;
			appearance: textfield;
		}
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
	.button-icon {
		background-color: transparent;
		border: none;
		i[class^='icon-'] {
			font-size: $size-md;
		}
		&[disabled] {
			background-color: var(--color-neutral-02);
			i[class^='icon-'] {
				color: var(--color-neutral-04);
			}
		}
	}

	&--sm {
		.button-icon {
			padding: var(--size-2xs);
		}
	}
}
