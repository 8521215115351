$font-family: 'inter', sans-serif;

// Body sizes
$b-48: 400 3rem / 3.75rem #{$font-family};
$b-32: 400 2rem / 2.5rem #{$font-family};
$b-24: 400 1.5rem / 2rem #{$font-family};
$b-18: 400 1.125rem / 1.625rem #{$font-family};
$b-16: 400 1rem / 1.5rem #{$font-family};
$b-14: 400 0.875rem / 1.5rem #{$font-family};

// Desktop title sizes
$h1-title: 700 3.5rem / 4.5rem #{$font-family};
$h2-title: 700 3rem / 4rem #{$font-family};
$h3-title: 700 2rem / 2.5rem #{$font-family};
$h4-title: 700 1.5rem / 2rem #{$font-family};
$h5-title: 700 1.125rem / 1.625rem #{$font-family};
