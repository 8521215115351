.card {
	@include flex($fd: column, $ai: center, $jc: center);
	gap: var(--size-xl);
	padding: var(--size-xl);
	border-radius: $br-xs;
	border: 1px solid $O-neutral-03;
	background: $O-white;
	text-align: center;
	&__text {
		@include flex($fd: column);
		gap: var(--size-sm);
		> span {
			font: $b-24;
		}
	}
	&--sm {
		padding: var(--size-lg) var(--size-md);
	}

	&:active {
		background: $O-neutral-01;
	}

	&--selected {
		border-color: $O-black;
		outline: 2px solid $O-black;
	}

	&-group {
		@include flex();
		gap: var(--size-3xl);
		app-card,
		.card {
			flex: 1;
			.card {
				height: 100%;
			}
		}
	}

	&[disabled] {
		background-color: $O-neutral-01;
		pointer-events: none;
		color: $O-neutral-05;
		h3 {
			color: $O-neutral-05;
		}
		i[class^='icon-'] {
			color: $O-neutral-04;
		}
	}
}
