.grid{
    display: grid;

    //CONTENIDO ESTÁTICO
    &-e2{
        grid-template-columns: repeat(2, 1fr);
        row-gap: var(--size-sm);
        column-gap: var(--size-lg);
        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
        }
        @media (max-width: $mobile) {
            grid-template-columns: repeat(2, 1fr);
            gap: var(--size-sm);
        }
        @media (max-width: $mobile-xs) {
            grid-template-columns: 1fr;
        }
    }
    &-e2-sm{
        grid-template-columns: repeat(2, 1fr);
        gap: var(--size-sm);
        @media (max-width: $mobile-xs) {
            grid-template-columns: 1fr;
        }
    }
    &-e3{
        grid-template-columns: repeat(3, 1fr);
        gap: var(--size-lg);
        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
            justify-items: center;
        };
    }
    &-e3-sm{
        grid-template-columns: repeat(3, 1fr);
        gap: var(--size-sm);
        @media (max-width: $mobile-xs) {
            grid-template-columns: 1fr;
        }
    }
    &-e4{
        grid-template-columns: repeat(4, 1fr);
        gap: var(--size-lg) ;
        @media (max-width: $desktop) {
            grid-template-columns: repeat(2, 1fr);
        };
        @media (max-width: $mobile-xs) {
            grid-template-columns: 1fr;
        };
    }
    &-e4-sm{
        grid-template-columns: repeat(4, 1fr);
        gap: var(--size-sm);
        @media (max-width: $desktop) {
            grid-template-columns: repeat(2, 1fr);
        };
        @media (max-width: $mobile-xs) {
            grid-template-columns: 1fr;
        };
    }
    &-e5{
        grid-template-columns: repeat(5, 1fr);
        gap: var(--size-sm);
        @media (max-width: $mobile) {
            grid-template-columns: repeat(3, 1fr);
        };
        @media (max-width: $mobile-xs) {
            grid-template-columns: repeat(2, 1fr);
        };
    }

    //CONTENIDO DINÁMICO
    &-d3{
        grid-template-columns: repeat(3, 1fr);
        gap: var(--size-sm);
        @media (max-width: $tablet) {
            grid-template-columns: repeat(2, 1fr);
        };
        @media (max-width: $mobile-xs) {
            grid-template-columns: 1fr;
        };
    }
    &-d4{
        grid-template-columns: repeat(4, 1fr);
        gap: var(--size-xl);
        @media (max-width: $tablet) {
            grid-template-columns: repeat(2, 1fr);
        };
        @media (max-width:$mobile-xs) {
            grid-template-columns: 1fr;
        };
    }

    //CONTENIDO INFORMATIVO (INPUTS/INFO-GROUP)
    &-i2{
        grid-template-columns: repeat(2, 1fr);
        gap: var(--size-3xl);
        @media (max-width:$mobile-xs ) {
            grid-template-columns: 1fr;
        };

    }
    &-i3{
        grid-template-columns: repeat(3, 1fr);
        gap: var(--size-md);
        &__2{
            grid-column-start: span 2;
        }
        @media (max-width: $mobile) {
            grid-template-columns: repeat(2, 1fr);
        };
        @media (max-width:$mobile-xs ) {
            grid-template-columns: 1fr;
        };
    }
}