.header{
    &__title{
        @include flex($ai:center, $jc:space-between);
        gap: var(--size-xl);
        padding: var(--size-3xl);
        &__logo{
            @include flex($ai:center, $jc:space-between);
            max-width: rem(72);
        }
    }
}