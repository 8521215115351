.animate-check {
    display: inline-block;
    vertical-align: top;
    height: rem(135);
    width: rem(135);
    opacity: 1;
    overflow: visible;
  
    @keyframes success-tick {
        0% {
            stroke-dashoffset: 16px;
            opacity: 1
        }
  
        100% {
            stroke-dashoffset: 31px;
            opacity: 1
        }
    }
  
    @keyframes success-circle-outline {
        0% {
            stroke-dashoffset: 72px;
            opacity: 1
        }
  
        100% {
            stroke-dashoffset: 0px;
            opacity: 1
        }
    }
  
    @keyframes success-circle-fill {
        0% {
            opacity: 0;
        }
  
        100% {
            opacity: 1;
        }
    }
  
    .success-tick {
        fill: none;
        stroke-width: 2px;
        stroke: $O-white;
        stroke-dasharray: 15px, 15px;
        stroke-dashoffset: -14px;
        animation: success-tick 450ms ease 1400ms forwards;
        opacity: 0;
    }
  
    .success-circle-outline {
        fill: none;
        stroke-width: 1px;
        stroke: $O-functional-green;
        stroke-dasharray: 72px, 72px;
        stroke-dashoffset: 72px;
        animation: success-circle-outline 300ms ease-in-out 800ms forwards;
        opacity: 0;
    }
  
    .success-circle-fill {
        fill: $O-functional-green;
        stroke: none;
        opacity: 0;
        animation: success-circle-fill 300ms ease-out 1100ms forwards;
    }
  }