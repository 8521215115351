.input{
    @include flex($ai:center);
    width: 100%;
    font-family: $font-family;
    background-color: $O-white;
    border: 3px solid $O-neutral-03;
    background-clip: padding-box;
    color: $O-black;
    padding: var(--size-sm) var(--size-md);
    font: $b-24;
    font-weight: 700;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    resize: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: $size-lg;

    &:disabled,
    &.disabled{
        pointer-events: none;
        background-color: $O-neutral-02;
        border-color: $O-neutral-03;
        color: $O-neutral-04;
    }

    &::placeholder {
        color: $O-neutral-03;
        opacity: 1;
    }

    &:focus{
        outline: none;
        transition: border-color 0.15s ease-in-out;
        border-color: $O-black;
    }

    &--error,
    &--error:focus{
        transition: border-color 0.15s ease-in-out;
        border-color: $O-functional-red;
    }
}