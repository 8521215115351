.button-toggle {
	@include flex($ai: center, $jc: center);
	background-color: $O-white;
	border-radius: $br-xs;
	border: 2px solid $O-neutral-04;
	color: $O-black;
	font: $b-24;
	font-size: 1.25rem;
	font-weight: 700;
	transition: all 0.15s ease-in-out;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	cursor: pointer;
	padding: var(--size-md);

	&--selected {
		background-color: $O-black;
		border-color: $O-black;
		color: $O-white;
	}

	&-group {
		@include flex($ai: center);
		gap: var(--size-md);
		.button-toggle {
			flex: 1;
			padding: var(--size-md) 0;
		}
	}
}
