.list {
	&__item {
		padding: var(--size-md);
		border-bottom: 1px solid $O-neutral-03;
		@include flex($fd: column);
		gap: var(--size-2xs);
		&__product {
			font: $b-18;
			color: $O-neutral-05;
		}
		&__details {
			@include flex($ai: center, $jc: space-between);
			gap: var(--size-md);
			.details {
				&__name {
					font: $b-32;
					&__image {
						img {
							max-height: rem(58);
							width: 100%;
						}
					}
				}
				&__info {
					@include flex($ai: center);
					gap: var(--size-md);
					font: $b-24;
					color: $O-neutral-05;
					h5 {
						width: rem(120);
						text-align: center;
						padding: var(--size-2xs) var(--size-sm);
					}
					.button {
						&--xs {
							color: $O-neutral-05;
						}
					}
				}
			}
		}
	}

	&--documents {
		border-top: 1px solid $O-neutral-03;
		.list {
			&__item {
				padding: $size-md $size-2xs;
				gap: $size-sm;
			}
		}
	}
}
