.alert {
	@include flex($ai: center);
	gap: var(--size-2xs);
	background-color: $O-feedback-yellow;
	padding: var(--size-2xs) var(--size-sm);
	width: fit-content;
	margin: 0 auto;
	i[class^='icon-'] {
		color: $O-supporting-yellow;
		font-size: $size-md;
		@include flex-child($fs: 0);
	}
	svg {
		@include flex-child($fs: 0);
	}
	&--sm {
		padding: 0;
		background-color: transparent;
		font: $b-24;
		&.success {
			i[class^='icon-'] {
				color: $O-functional-green;
			}
		}
	}
	&--md {
		padding: var(--size-sm) var(--size-md);
		font: $b-24;
		gap: $size-md;
	}

	&--info {
		background-color: $O-feedback-blue;
		padding: var(--size-sm) var(--size-md);
		width: 100%;
		font: $b-18;
		i[class^='icon-'] {
			font-size: $size-xl;
			color: $O-black;
		}
	}

	&--animated {
		opacity: 0;
		transform: translateY(5px);
		transition: all 0.15s ease-in-out;
		&.show {
			opacity: 1;
			transform: translateY(0px);
			transition: all 0.15s ease-in-out;
		}
	}

	&-container {
		max-width: rem(800);
		width: 100%;
		margin: 0 auto;
		@include flex($ai: center, $fd: column);
		gap: $size-md;
		.alert {
			width: 100%;
		}
	}
}
