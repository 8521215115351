.collapse {
	border-bottom: 1px solid $O-neutral-03;
	&:first-child {
		border-top: 1px solid $O-neutral-03;
	}

	&__header {
		@include flex($ai: center, $jc: space-between);
		gap: var(--size-sm);
		padding: var(--size-sm) var(--size-2xs);
		cursor: pointer;
		&__title {
			@include flex($ai: center);
			@include flex-child($fg: 2);
			gap: var(--size-sm);
			.title {
				&__text {
					@include flex($fd: column);
					gap: var(--size-3xs);
				}
			}
		}

		> i[class^='icon-'] {
			@include flex-child($fs: 0);
			transition: transform 0.5s ease-in-out;
		}
	}

	&__body {
		max-height: 0;
		transition:
			max-height 0.35s ease-in-out,
			padding 0.35s ease-in-out;
		overflow: hidden;
		padding: 0 var(--size-2xs);
		color: $O-neutral-05;
	}

	&--expanded {
		@include position($p: relative);
		.collapse {
			&__header {
				> i[class^='icon-'] {
					transform: rotate(-180deg);
					transition: transform 0.5s ease-in-out;
				}
			}

			&__body {
				max-height: rem(280);
				padding: 0 var(--size-2xs) var(--size-sm) var(--size-2xs);
				transition:
					max-height 0.35s ease-in-out,
					padding 0.35s ease-in-out;
				overflow: auto;
			}
		}
		&:before {
			content: '';
			@include position($p: absolute, $b: -3px, $l: 0, $r: 0);
			width: 100%;
			height: rem(52);
			background: linear-gradient(
				0deg,
				rgb(255, 255, 255) 0%,
				rgb(255, 255, 255) 32%,
				rgba(255, 255, 255, 0.7287289916) 76%,
				rgba(255, 255, 255, 0) 100%
			);
			border-bottom: 1px solid $O-neutral-03;
			opacity: 0;
			transition: opacity 0.15s ease-in-out;
		}
		&.not-end {
			&:before {
				opacity: 1;
				transition: opacity 0.15s ease-in-out;
			}
		}
	}

	&-group {
		@include flex($fd: column);
	}

	&--lg {
		.collapse {
			&__header {
				padding: var(--size-md) var(--size-2xs);
				&__title {
					.title {
						&__text {
							h5 {
								font: $h4-title;
							}
						}
					}
				}
			}
			&__body {
				font: $b-18;
				ul {
					padding-left: rem(20);
				}
			}
		}
		&.collapse {
			&--expanded {
				.collapse {
					&__body {
						padding-bottom: var(--size-md);
					}
				}
			}
		}
	}

	&-carousel {
		@include flex($fd: column, $ai: center);
		gap: $size-xl;
		.collapse {
			opacity: 0;
			height: 0;
			visibility: hidden;
			transition: opacity 0.15s ease-in-out;
			&-group {
				border-top: 1px solid $O-neutral-03;
			}
			&__body {
				max-height: 100vh;
				padding: 0 var(--size-2xs) var(--size-sm) var(--size-2xs);
				transition:
					max-height 0.35s ease-in-out,
					padding 0.35s ease-in-out;
			}
			&.show {
				opacity: 1;
				transition: opacity 0.15s ease-in-out;
				height: auto;
				visibility: visible;
			}
		}
		&__pagination {
			@include flex($jc: center, $ai: center);
			gap: $size-md;
			span {
				font: $b-24;
			}
		}
	}
}
