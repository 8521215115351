.accessibility-block{
    height: 0;
    @include flex-child($fs:0);
    padding: 0 var(--size-2xs);
    background-color: $O-black;
    @include flex($ai:center, $jc:center);
    transition: all .5s ease-in-out;
    img{
        max-width: rem(152);
        opacity: 0;
        transition: all .15s ease-in-out;
    }
}