
//ORANGE COLORS

$O-black: #000000;
$O-white: #FFFFFF;
$O-orange: #FF7900;

$O-supporting-blue: #4BB4E6;
$O-supporting-green: #50BE87;
$O-supporting-pink: #FFB4E6;
$O-supporting-purple: #A885D8;
$O-supporting-yellow: #FFD200;

$O-feedback-blue: #B5E8F7;
$O-feedback-green: #B8EBD6;
$O-feedback-pink: #FFD9F2;
$O-feedback-purple: #D9C2F0;
$O-feedback-yellow: #FFFBE5;

$O-neutral-01: #FAFAFA;
$O-neutral-02: #EEEEEE;
$O-neutral-03: #D6D6D6;
$O-neutral-04: #8F8F8F;
$O-neutral-05: #595959;

$O-functional-blue: #527EDB;
$O-functional-green: #228722;
$O-functional-red: #CD3C14;
$O-functional-yellow: #FFCC00;