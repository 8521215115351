//Border Radius
$br-2xs: 2px;
$br-xs: 4px;
$br-sm: 8px;
$br-md: 16px;
$br-lg: 116px;

//Box shadow
$bs-soft: 4px 8px 16px 0px rgba(0, 0, 0, 0.05);

//Sizes
$size-4xs: 0.125rem; //2
$size-3xs: 0.25rem; //4
$size-2xs: 0.5rem; //8
$size-xs: 0.75rem; //12
$size-sm: 1rem; //16
$size-md: 1.5rem; //24
$size-lg: 2rem; //32
$size-xl: 2.5rem; //40
$size-2xl: 3rem; //48
$size-3xl: 4rem; //64
$size-4xl: 5rem; //80
$size-5xl: 7.25rem; //116
