.button-icon {
    @include flex($ai: center, $jc: center);
    @include position ($p: relative);
    background-color: $O-white;
    border: 2px solid $O-black;
    color: $O-black;
    padding: var(--size-sm);
    transition: all .15s ease-in-out;
    cursor: pointer;

    i[class^='icon-'] {
        font-size: $size-md;
        transition: all .15s ease-in-out;
    }

    &[disabled]{
        border-color: $O-neutral-03;
        color: $O-neutral-03;
    }

    &--xs{
        padding: var(--size-2xs);
    }
}