.cart{
    border: 1px solid $O-neutral-03;
    padding: var(--size-lg);
    box-shadow: $bs-soft;
    font:$b-24;
    &__item{
        @include flex($jc:space-between);
        gap: var(--size-4xl);
    }

    &--xl{
        @include flex($fd:column);
        gap: var(--size-3xl);
        .cart{
            &__summary{
                &__item{
                    @include flex($jc:space-between);
                    color: $O-neutral-05;
                }
                &__total{
                    @include flex($jc:space-between);
                    padding-top: var(--size-md);
                    margin-top: var(--size-md);
                    border-top: 1px solid $O-neutral-03;
                    .total{
                        &__text{
                            @include flex($fd:column, $ai:flex-start);
                            span{
                                font: $b-18;
                                color: $O-neutral-05;
                            }
                        }
                    }
                }
            }
            &__actions{
                .button{
                    width: 100%;
                }
            }
        }
    }

    &-operation{
        @include flex($fd:column);
        gap: var(--size-md);
        border: 1px solid $O-neutral-03;
        padding: var(--size-md) var(--size-xl);
        box-shadow: $bs-soft;
        font:$b-32;
        &__values{
            @include flex();
            gap: var(--size-4xl);
            &__total,
            &__miss{
                flex:1;
                text-align: center;
            }
        }
        .alert{
            align-self: center;
        }
    }
}