.image-group {
	@include flex($fd: column, $ai: center);
	gap: $size-md;
	&__text {
		text-align: center;
		color: $O-neutral-05;
		font: $b-14;
	}
	&-wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: $size-xl;
		padding: $size-sm $size-xl;
	}
}
