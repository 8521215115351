:root {
	--size-4xs: 0.125rem; //2
	--size-3xs: 0.25rem; //4
	--size-2xs: 0.5rem; //8
	--size-xs: 0.75rem; //12
	--size-sm: 1rem; //16
	--size-md: 1.5rem; //24
	--size-lg: 2rem; //32
	--size-xl: 2.5rem; //40
	--size-2xl: 3rem; //48
	--size-3xl: 4rem; //64
	--size-4xl: 5rem; //80
	--size-5xl: 7.25rem; //116
}

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	scroll-behavior: smooth;

	*::selection {
		color: $O-black;
		background: $O-neutral-03;
	}

	-webkit-tap-highlight-color: transparent;

	body {
		font: $b-16;
		color: $O-black;

		app-root {
			@include flex($fd: column);
			font: $b-16;
			margin: 0;
			height: 100vh;
			color: $O-black;
			overflow: hidden;
			&.welcome {
				.main {
					padding: 0;
					overflow: hidden;
					height: auto;
				}
				.nav {
					min-height: rem(220);
				}
			}
			&.end {
				.main {
					background-color: $O-black;
					padding: var(--size-4xl) var(--size-4xl) rem(120) var(--size-4xl);
					&__container {
						@include flex($fd: column, $ai: center, $jc: center);
						@include flex-child($fg: 2);
						gap: rem(136);
						text-align: center;
						&__logo {
							img {
								max-width: rem(72);
							}
						}
						&__title {
							@include flex($fd: column);
							gap: var(--size-xl);
							max-width: rem(736);
							span {
								font: $b-32;
							}
							span,
							h2 {
								color: $O-white;
							}
						}
					}
					.button {
						max-width: rem(768);
						width: 100%;
						margin: auto;
					}
				}
			}
		}

		*,
		*::before,
		*::after {
			box-sizing: border-box;
		}

		h5,
		.h5,
		h4,
		.h4,
		h3,
		.h3,
		h2,
		.h2,
		h1,
		.h1 {
			margin-top: 0;
			margin-bottom: 0;
			color: $O-black;
		}

		h1,
		.h1 {
			font: $h1-title;
			@include flex($ai: center);
			gap: var(--size-lg);
		}
		h2,
		.h2 {
			font: $h2-title;
		}
		h3,
		.h3 {
			font: $h3-title;
		}
		h4,
		.h4 {
			font: $h4-title;
		}
		h5,
		.h5 {
			font: $h5-title;
		}

		a {
			color: $O-black;
			text-decoration: underline;
			transition: color 0.15s ease-in-out;
			cursor: pointer;
			&:hover {
				color: $O-black;
			}
		}

		.main {
			@include flex-child($fg: 2);
			padding: var(--size-5xl) var(--size-3xl) var(--size-3xl) var(--size-3xl);
			@include flex($fd: column, $jc: flex-start);
			gap: rem(106);
			height: calc(100vh - 37.5rem);
			overflow: auto;

			&__header {
				@include flex($fd: column);
				gap: var(--size-md);
				@include flex-child($fs: 0);
				> span {
					font: $b-32;
					color: $O-neutral-04;
					@include flex($jc: space-between, $ai: center);
					a {
						font: $b-16;
					}
				}
			}
			&__content {
				@include flex($fd: column);
				gap: var(--size-3xl);
				@include flex-child($fg: 2);
				&__section {
					.section {
						&__title {
							@include flex($fd: column);
							gap: var(--size-sm);
							span {
								font: $b-24;
								color: $O-neutral-04;
							}
						}
					}
					+ .main__content__section {
						border-top: 1px solid $O-neutral-03;
						@include flex($fd: column);
						gap: var(--size-xl);
						padding-top: var(--size-3xl);
					}
				}

				.keyboard {
					width: rem(392);
					padding: var(--size-sm);
					border: 1px solid $O-neutral-03;
					box-shadow: 4px 8px 8px 0px rgba(0, 0, 0, 0.05);
					.hg-theme-default {
						.hg-row {
							margin-top: var(--size-2xs);
							+ .hg-row {
								padding: 0;
							}
						}
						&.hg-layout-numeric {
							.hg-button {
								border-radius: $br-xs;
								height: var(--size-2xl);
							}
						}
					}
				}
			}

			&__link {
				@include flex($jc: center);
			}

			&--loader {
				@include flex($jc: center);
			}
		}

		.document {
			margin: auto;
			border-radius: var(--size-2xs);
			border: 1px solid $O-neutral-03;
			overflow: hidden;
		}
	}
}
